<template>
  <div>
    <l-map
      ref="myMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @mousemove="mousemove"
      @mouseleave="shadowMarker = null"
      @click="onMapClick"
    >
      <l-tile-layer :url="url" />
      <l-marker v-if="shadowMarker" ref="shadowMarker" :lat-lng="shadowMarker" :icon="dicon" />
      <l-marker v-if="markerPos" ref="location" :lat-lng="markerPos" />
    </l-map>
  </div>
</template>

<script>
import { latLng, icon as LIcon } from 'leaflet'

export default {
  props: {
    value: { type: null, default: () => (null) },
    mapCenter: { type: null, default: () => (null) }
  },
  data() {
    return {
      model: null,
      dropdown: false,
      mounted: false,
      zoom: 20,
      center: this.mapCenter || latLng(55.753600, 37.621094),
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      url: 'https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=qpxIelfEYMtbW1mf7NoqmR0sjfT7M3iiXhEz1qFmmlDdZN2VTKX7g24wHf9NAKib',
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      },
      shadowMarker: null,
      dicon: LIcon({
        iconUrl: require('@cpanel/assets/img/marker.png'),
        iconSize: [36, 36],
        iconAnchor: [16, 37]
      })
    }
  },
  computed: {
    markerPos() {
      return this.value
    }
  },
  watch: {
    value(val) {
      if (val && val.lat && val.lng && this.$refs['location']) {
        this.$refs['location'].mapObject.setLatLng(val)
        this.$refs['myMap'].mapObject.flyTo(val, 16, { animate: true, duration: 1 })
      }
    },
    mapCenter(val) {
      if (!val) return
      const pos = latLng(val.lat, val.lng)
      this.$refs['myMap'].mapObject.flyTo(pos, this.zoom, { animate: false })
    }
  },
  mounted() {
    setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 100)
  },
  methods: {
    mousemove(e) {
      this.shadowMarker = e.latlng
      this.$nextTick(() => {
        if (this.$refs['newLocation']) this.$refs['newLocation'].mapObject.setLatLng(this.shadowMarker)
      })
    },
    onMapClick(e) {
      this.$emit('input', e.latlng)
    }
  }
}
</script>

<style scoped>

</style>
