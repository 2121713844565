var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"align-start pa-0 overflow-hidden",staticStyle:{"flex-direction":"column","height":"calc(100vh - 50px)"}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"app-place-table",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":50,"item-key":"_id","fixed-header":"","height":"390","loading-text":"Получение данных...","no-data-text":"нет данных","show-select":""},on:{"click:row":_vm.onRowClick,"input":_vm.onSelectionChanged},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[_c('div',[_vm._v(_vm._s(item.name))])])]}},{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","color":"red"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',{staticClass:"red--text",on:{"click":function($event){return _vm.onDeleteClick(item)}}},[_vm._v(" Удалить ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }