<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col cols="auto" class="col-sm-8 col-md-6 text-center">
        <div class="py-6">
          <div class="pa-4">
            <v-icon size="96" class="primary--text text--darken-1">mdi-map-marker-radius</v-icon>
          </div>
          <h3>Создайте первую локацию</h3>
          <p class="pt-6 body-2">
            Укажите место, откуда консультаны выходят на связь.
            Это может быть ваш магазин, шоурум, офис.
            Или можно указать просто город, без точного адреса.
          </p>
        </div>
        <v-btn color="primary" @click="$emit('place:add')">Добавить локацию</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
