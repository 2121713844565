<template>
  <div style="width: 100%; height: 100%;">
    <div style="height: 100%; overflow: auto;">
      <l-map
        ref="myMap"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        style="height: 80%"
        @contextmenu="onRightClick"
      >
        <l-tile-layer :url="url" />
        <v-marker-cluster ref="cluster">
          <l-marker
            v-for="(marker, idx) in markers"
            :key="idx"
            :lat-lng="marker.pos"
            :options="marker.options"
            @click="e => (onMarkerClick(marker, e))"
            @contextmenu="e => onRightClick(e, marker)"
          />
        </v-marker-cluster>
      </l-map>
    </div>
    <v-menu
      v-model="showMenu"
      :position-x="menuX"
      :position-y="menuY"
      absolute
      offset-y
    >
      <v-list :subheader="!!selectedMarker" dense>
        <v-list-item link @click="onAddPlaceClick">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Добавить локацию здесь</v-list-item-title>
        </v-list-item>
        <v-divider v-if="selectedMarker" />
        <v-list-item v-if="selectedMarker" link @click="onEditPlaceClick(selectedMarker.place)">
          <v-list-item-icon>
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Изменить</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="selectedMarker" link @click="onDeletePlaceClick(selectedMarker.place)">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="red--text">Удалить</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { latLng, latLngBounds } from 'leaflet'

export default {
  props: {
    items: { type: Array, default: () => ([]) }
  },
  data() {
    return {
      zoom: 3,
      center: latLng(61.185625, 88.945313),
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // url: 'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png',
      url: 'https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=qpxIelfEYMtbW1mf7NoqmR0sjfT7M3iiXhEz1qFmmlDdZN2VTKX7g24wHf9NAKib',
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      },
      showMenu: false,
      menuX: 0,
      menuY: 0,
      menuEvent: null,
      selectedMarker: null
    }
  },
  computed: {
    markers() {
      return this.items.map(place => ({
        place: place,
        pos: latLng(place.lat || 0, place.lng || 0),
        options: {
          title: `${place.name}, ${place.address || ''}`,
          alt: place.name
        }
      }))
    }
  },
  watch: {
    items() {
      this.$nextTick(() => {
        this.fitPlaces()
      })
    }
  },
  mounted() {
    this.$nextTick(() => { this.fitPlaces() })
  },
  methods: {
    fitPlaces() {
      const corner1 = latLng(90, 180)
      const corner2 = latLng(-180, -180)

      this.items.forEach(el => {
        if (el.lat < corner1.lat) corner1.lat = el.lat
        if (el.lng < corner1.lng) corner1.lng = el.lng
        if (el.lat > corner2.lat) corner2.lat = el.lat
        if (el.lng > corner2.lng) corner2.lng = el.lng
      })
      const bounds = latLngBounds(corner1, corner2)
      this.$refs['myMap'].mapObject.fitBounds(bounds, { animate: false })
    },
    onMarkerClick(marker) {
      this.$emit('place:edit', marker.place)
    },
    onRightClick(e, marker = null) {
      e.originalEvent.preventDefault()
      this.showMenu = false
      this.menuX = e.originalEvent.clientX
      this.menuY = e.originalEvent.clientY
      this.menuEvent = e
      this.selectedMarker = marker
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    onAddPlaceClick() {
      const defPlace = {
        name: '',
        address: '',
        lat: this.menuEvent.latlng.lat,
        lng: this.menuEvent.latlng.lng
      }
      this.$emit('place:add', defPlace)
    },
    onEditPlaceClick(place) {
      this.$emit('place:edit', place)
    },
    onDeletePlaceClick(place) {
      this.$emit('place:delete', place)
    }
  }
}

</script>

<style scoped>
</style>
