<template>
  <v-combobox
    :search-input.sync="searchAddress"
    :items="geoFromAddress"
    :return-object="false"
    item-text="name"
    item-value="name"
    no-filter
    :loading="$apollo.queries.geoFromAddress.loading"
    v-bind="$attrs"
    @input="onInput"
  >
    <template v-slot:item="{ item, on }">
      <v-list-item v-if="item" @click="onItemClick(item)" v-on="on">
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { debounce } from '@common/helpers/debounce'

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String, default: () => (null)
    }
  },
  data() {
    return {
      searchAddress: this.value
    }
  },
  apollo: {
    geoFromAddress: {
      query: require('@cpanel/graphql/place-geo-from-address.gql'),
      variables() {
        return {
          address: this.searchAddress
        }
      },
      skip() { return !this.searchAddress || this.searchAddress.length < 4 }
    }
  },
  watch: {
    model: debounce(function(val) { this.searchAddress = val }, 500),
    searchAddress() {
      this.$emit('input', this.searchAddress)
    },
    value(val) {
      this.searchAddress = val
      if (!val) this.geoFromAddress = []
    }
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    },
    onItemClick(addressObject) {
      this.$emit('address:select', addressObject)
    }
  }
}
</script>

<style scoped>
</style>
