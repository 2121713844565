<template>
  <v-card>
    <v-card-title>
      <span class="headline">
        {{ isNew ? 'Новая локация' : 'Изменение локации' }}
      </span>
    </v-card-title>
    <v-card-text class="px-0">
      <v-container>
        <v-form v-if="model" ref="form" v-model="valid" :lazy-validation="!isNew">
          <v-row>
            <v-col cols="12" class="col-sm-6">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="model.name"
                    :counter="64"
                    :rules="[rules.required, rules.placename]"
                    label="Название"
                    required
                    autofocus
                    @keyup.esc="$emit('cancel:click')"
                  />
                </v-col>
                <v-col cols="12">
                  <address-input
                    v-model="model.address"
                    label="Адрес"
                    @address:select="onAddressSelected"
                    @keyup.esc="$emit('cancel:click')"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="col-sm-6">
              <map-input
                v-if="model"
                v-model="model.latLng"
                label="Координаты"
                required
                readonly
                :map-center="mapCenter"
                style="width: 100%; height: 300px;"
                append-icon="mdi-map-marker-plus"
                @keyup.esc="$emit('cancel:click')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-space-around justify-md-center">
      <v-btn text :disabled="loading" @click.stop="onCancel">Отмена</v-btn>
      <v-btn color="primary" :disabled="!valid || loading" :loading="loading" @click.stop="save">
        {{ isNew ? 'Создать' : 'Сохранить' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import GQL_QUERY_PLACES from '@cpanel/graphql/place-list.gql'
import GQL_ADD_PLACE from '@cpanel/graphql/place-add.mutation.gql'
import GQL_UPDATE_PLACE from '@cpanel/graphql/place-update.mutation.gql'

import AddressInput from '@cpanel/components/address-input/address-input'
import MapInput from '@cpanel/components/map-input/map-input'
import rules from '@cpanel/includes/form-rules'

export default {
  components: { MapInput, AddressInput },
  props: {
    value: { type: null, default: () => (null) }
  },
  data() {
    return {
      valid: false,
      loading: false,
      model: null,
      mapCenter: null,
      addressGeoPos: null,
      rules
    }
  },
  computed: {
    placeModel() {
      const place = {
        name: this.model.name,
        address: this.model.address,
        lat: this.model.latLng.lat,
        lng: this.model.latLng.lng
      }
      if (this.model._id) place._id = this.model._id
      return place
    },
    mutation() {
      return this.isNew ? GQL_ADD_PLACE : GQL_UPDATE_PLACE
    },
    isNew() {
      return !this.model || !this.model._id
    }
  },
  watch: {
    value(newVal) {
      this.setModel(newVal)
    }
  },
  created() {
    this.setModel()
  },
  mounted() {
    this.setModel(this.value)
  },
  methods: {
    setModel(val = null) {
      if (!val && this.$refs.form) this.$refs.form.reset()
      val = val || {}
      this.model = {
        _id: val._id || null,
        name: val.name || '',
        address: val.address || '',
        latLng: (val.lat || val.lng) ? { lat: val.lat || 0, lng: val.lng || 0 } : { lat: 55.753600, lng: 37.621094 }
      }
      this.mapCenter = this.model.latLng
      this.valid = false
    },
    onAddressSelected(address) {
      this.model.name = this.model.name || address.title
      this.model.latLng = address.latLng
      this.addressGeoPos = address
    },
    async save() {
      try {
        this.loading = true
        const data = await this.$apollo.mutate({
          mutation: this.mutation,
          variables: { place: this.placeModel },
          update: this.updateCache
        })
        this.afterSave(data)
      } catch (e) {
        this.onError(e)
      } finally {
        this.loading = false
      }
    },
    afterSave({ data }) {
      const place = this.isNew ? data.addPlace : data.updatePlace
      this.setModel()
      this.$emit('after:save', place)
    },
    onError(err) {
      this.$store.dispatch('dialog/error', err)
    },
    async onDeleteClick() {
      await this.deletePlace()
      this.setModel()
      this.$emit('after:delete')
    },
    onCancel() {
      this.setModel()
      this.$emit('cancel:click')
    },
    updateCache(store, { data: response }) {
      try {
        const place = this.isNew ? response.addPlace : response.updatePlace
        const data = store.readQuery({ query: GQL_QUERY_PLACES })
        if (this.isNew) {
          data.places.push(place)
          this.$emit('after:insert', data.places)
        } else {
          const placeIdx = data.places.findIndex(p => (p._id === place._id))
          if (placeIdx >= 0) data.places[placeIdx] = place
          this.$emit('after:update', data.places)
        }
        store.writeQuery({ query: GQL_QUERY_PLACES, data })
      } catch (e) {
        console.error(e)
      }
    },
    deleteFromCache(idsToDelete, store, { data: { deletePlaces: response }}) {
      if (response) {
        const data = store.readQuery({ query: GQL_QUERY_PLACES })
        data.places = data.places.filter(p => (!idsToDelete.includes(p._id)))
        store.writeQuery({ query: GQL_QUERY_PLACES, data })
        this.$emit('after:delete', idsToDelete)
        this.$emit('after:save', null)
      }
    },
    async deletePlace() {
      if (this.isNew) return
      const ids = [this.model._id]
      await this.$apollo.mutate({
        mutation: require('@cpanel/graphql/place-delete.mutation.gql'),
        variables: { ids: ids },
        update: this.deleteFromCache.bind(this, ids)
      })
    }
  }
}
</script>

<style scoped>

</style>
