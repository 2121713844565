<template>
  <v-container fluid class="fill-height align-start">
    <div v-if="$apollo.loading" class="d-flex justify-center align-center" style="height: 100%; width: 100%;">
      <v-progress-circular
        :size="48"
        color="primary"
        indeterminate
      />
    </div>
    <v-toolbar v-if="!$apollo.loading && (places || []).length" flat>
      <v-btn-toggle v-model="view" tile color="deep-blue accent-3" group>
        <v-btn value="map" text>Карта</v-btn>
        <v-btn value="list" text>Список</v-btn>
      </v-btn-toggle>
      <v-icon>mdi-dots-vertical</v-icon>
      <v-slide-x-transition mode="out-in">
        <v-btn v-if="selected.length" text color="red" @click="deletePlaces()">
          <v-icon>mdi-delete</v-icon>
          <v-badge color="red" :content="selected.length" :value="selected.length > 1" inline>
            Удалить
          </v-badge>
        </v-btn>
      </v-slide-x-transition>
      <v-btn icon @click="refresh"><v-icon>mdi-refresh</v-icon></v-btn>
      <v-spacer />
      <v-btn color="primary" @click="onPlaceAddClick">Добавить</v-btn>
    </v-toolbar>
    <v-row class="fill-height">
      <v-col>
        <component
          :is="viewComponent"
          :items="places"
          @place:select="onPlaceSelect"
          @place:add="onPlaceAddClick"
          @place:edit="onPlaceEditClick"
          @place:delete="onPlaceDeleteClick"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="addPlaceDialog"
      scrollable
      persistent
      width="auto"
      max-width="800"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <add-place-form
        class="pa-6"
        :value="placeModel"
        @cancel:click="onCancel"
        @after:save="onPlacesChanged"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import ViewAsMap from './components/places-as-map'
import ViewAsTable from './components/places-as-table'
import ViewStarter from './components/place-starter'
import AddPlaceForm from './components/add-place-form'

export default {
  components: { AddPlaceForm, ViewAsMap, ViewAsTable },
  data() {
    return {
      view: 'map',
      placeModel: null,
      addPlaceDialog: false,
      selected: []
    }
  },
  computed: {
    viewComponent() {
      if (!(this.places || []).length) {
        return ViewStarter
      } else {
        return this.view === 'map' ? ViewAsMap : ViewAsTable
      }
    }
  },
  apollo: {
    places: {
      query: require('@cpanel/graphql/place-list.gql')
    }
  },
  watch: {
    view() {
      this.selected = []
    }
  },
  methods: {
    refresh() {
      this.error = false
      try {
        this.$apollo.queries.places.refetch()
      } catch (e) {
        this.$toast.error(e)
      }
    },
    onPlacesChanged() {
      this.placeModel = null
      this.addPlaceDialog = false
    },
    onPlaceSelect(places) {
      this.selected = places
    },
    onCancel() {
      this.addPlaceDialog = false
      this.placeModel = null
    },
    onPlaceAddClick(defPlace = null) {
      this.placeModel = defPlace
      this.addPlaceDialog = true
    },
    onPlaceEditClick(place) {
      this.placeModel = place
      this.addPlaceDialog = true
    },
    onPlaceDeleteClick(place) {
      this.deletePlaces([place._id])
    },
    async deletePlaces(idsToDelete = null) {
      const ids = idsToDelete || this.selected.map(place => place._id)
      try {
        await this.$apollo.mutate({ mutation: require('../../graphql/place-delete.mutation.gql'), variables: { ids: ids }})
        this.selected = []
        await this.refresh()
      } catch (e) {
        this.$toast.error(e.toString())
      }
    }
  }
}
</script>

<style scoped>

</style>
