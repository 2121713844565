<template>
  <v-container class="align-start pa-0 overflow-hidden" style="flex-direction: column; height: calc(100vh - 50px)">
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="50"
          item-key="_id"
          fixed-header
          height="390"
          loading-text="Получение данных..."
          no-data-text="нет данных"
          show-select
          class="app-place-table"
          @click:row="onRowClick"
          @input="onSelectionChanged"
        >
          <template v-slot:item.name="{ item }">
            <div class="pt-2 pb-2">
              <div>{{ item.name }}</div>
            </div>
          </template>
          <template v-slot:item._actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item link color="red">
                  <v-list-item-icon>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="red--text" @click="onDeleteClick(item)">
                    Удалить
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  pageSize: 10,
  props: {
    items: { type: Array, default: () => ([]) }
  },
  data() {
    return {
      selected: [],
      headers: [
        { text: 'Название', value: 'name', width: '240px', divider: true },
        { text: 'Адрес', value: 'address' },
        {
          value: '_actions',
          text: 'Действия',
          width: '120px',
          sortable: false,
          align: 'end'
        }
      ]
    }
  },
  methods: {
    onRowClick(item) {
      this.$emit('place:edit', item)
    },
    onDeleteClick(place) {
      this.$emit('place:delete', place)
    },
    onSelectionChanged(data) {
      this.$emit('place:select', data)
    }
  }
}

</script>

<style scoped>
  .app-place-table >>> tbody > tr {
    cursor: pointer;
  }
</style>
